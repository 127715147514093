<template>
  <v-container>
    <h1>
      <span>Создание новой проводки</span>
    </h1>
    <v-form ref="form">
      <section class="form-wrap">
        <v-row>
          <v-col cols="12">
            <h2>Информация</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Год по бюджету*"
              v-model="wData.budgetYear"
              :items="years"
              dense
              outlined
              :disabled="yearDisabled"
              @change="
                entriesDict = entries.filter((e) => e.year == wData.budgetYear);
                year = wData.budgetYear;
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Проект"
              :items="entriesDict"
              item-text="name"
              item-value="id"
              return-object
              v-model="wData.project"
              outlined
              dense
              clearable
              :disabled="!!projectId"
              @change="
                if (wData.project) {
                  wData.budgetYear = wData.project.year;
                  yearDisabled = true;
                } else {
                  wData.budgetYear = new Date().getFullYear();
                  yearDisabled = false;
                }
                if (wData.project && wData.wiringTypeDir) {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == true
                  );
                } else if (wData.wiringTypeDir) {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == false
                  );
                }
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Организация"
              :items="ourOrgs"
              item-text="shortName"
              item-value="id"
              return-object
              v-model="wData.organization"
              dense
              outlined
              clearable
              @change="
                if (wData.organization) {
                  if (wData.organization.accounts != null) {
                    accountsDict = [...wData.organization.accounts];
                  } else {
                    accountsDict = [
                      'Необходимо заполнить номер счета организации.',
                    ];
                  }
                } else {
                  accountsDict = [];
                  wData.accountNumber = null;
                }
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              :items="accountsDict"
              v-model="wData.accountNumber"
              label="Номер счета"
              dense
              outlined
              :disabled="!wData.organization"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Ответственный*"
              :items="experts"
              item-text="fullName"
              item-value="id"
              return-object
              v-model="wData.expert"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>тип проводки*</h4>
            <v-radio-group
              row
              v-model="wData.wiringType"
              required
              :rules="rules.select"
            >
              <v-radio :value="1" label="Планируемая"></v-radio>
              <v-radio :value="2" label="Фактическая"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: 40px">
          <v-col cols="12">
            <h4>Приход/Расход*</h4>
            <v-radio-group
              v-model="wData.wiringTypeDir"
              @change="
                if (wData.project && wData.wiringTypeDir) {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == true
                  );
                } else {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == false
                  );
                }
                wData.funds = ' ';
                wData.article = ' ';
                wData.paymentMethod = ' ';
                articles = [];
                paymentMethods = [];
              "
              :rules="rules.select"
              required
            >
              <v-radio value="expences" label="Расход"></v-radio>
              <v-radio value="income" label="Доход"></v-radio>
              <v-radio
                value="internalRedistribution"
                label="Перераспределение внутреннее"
                :disabled="!!wData.project"
              ></v-radio>
              <v-radio value="securityDeposit" label="Обеспечение"></v-radio>
              <v-radio
                value="externalRedistribution"
                label="Перераспределение внешнее"
                :disabled="!!wData.project"
              ></v-radio>
              <v-radio
                value="reserve"
                label="Резерв"
                :disabled="!!wData.project"
              ></v-radio>
              <v-radio
                value="nonfund"
                label="Нефондовые"
                :disabled="!!wData.project"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Фонд*"
              :items="funds"
              item-text="fund"
              item-value="id"
              return-object
              v-model="wData.funds"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.funds == ' ') {
                  wData.funds = null;
                }
              "
              @change="
                articles = articlesDict[wData.funds.id];
                paymentMethods = [];
                wData.article = ' ';
                wData.paymentMethod = ' ';
                paymentMethods = [];
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Статья*"
              :items="articles"
              item-text="article"
              item-value="id"
              return-object
              v-model="wData.article"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.article == ' ') {
                  wData.article = null;
                }
              "
              @change="
                paymentMethods = [
                  { id: 1, text: 'Наличный', value: wData.article.cash },
                  {
                    id: 2,
                    text: 'Безналичный',
                    value: wData.article.cashless,
                  },
                ].filter((el) => el.value == true);
                wData.paymentMethod = ' ';
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 40px">
          <v-col cols="12">
            <v-select
              label="Способ оплаты*"
              :items="paymentMethods"
              item-text="text"
              item-value="id"
              return-object
              v-model="wData.paymentMethod"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.paymentMethod == ' ') {
                  wData.paymentMethod = null;
                }
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <date-field
              :label="
                wData.wiringType != 1
                  ? 'Дата оплаты*'
                  : 'Дата оплаты (планируемая)*'
              "
              :value="wData.paymentDate"
              @input="wData.paymentDate = $event"
              v-bind:min-date="
                wData.wiringType == 1 ? getTodayDateString() : '2000-01-01'
              "
              max-date="2029-12-31"
            ></date-field>
          </v-col>
        </v-row>

        <register-select-field
          field-title="Договор"
          dialog-title="Выбор договора"
          :reg-config="customerContractsRegisterConfig"
          page-size="10"
          default-tab="0"
          :text-function="(item) => item.theme || ''"
          text-search-attribute="theme"
          :value-object="wData.contractId"
          @select="
            wData.contractId = $event;
            $forceUpdate();
          "
          @clear="
            wData.contractId = null;
            $forceUpdate();
          "
          not-null="true"
        >
        </register-select-field>

        <register-select-field
          field-title="Контрагент"
          dialog-title="Выбор контрагент"
          :reg-config="counterpartiesRegisterConfig"
          page-size="10"
          default-tab="0"
          :text-function="(item) => item.fullName || ''"
          text-search-attribute="fullName"
          :value-object="wData.contractor"
          @select="
            wData.contractor = $event;
            $forceUpdate();
          "
          @clear="
            wData.contractor = null;
            $forceUpdate();
          "
          not-null="true"
        >
        </register-select-field>

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Сумма (руб.)*"
              v-model="wData.sum"
              dense
              outlined
              :rules="[
                (v) =>
                  v == null ||
                  v.length === 0 ||
                  !!normalizeNumber(v, 0, 1e13, 2) ||
                  'Введите корректную сумму',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Примечание"
              v-model="wData.comment"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Связанные проводки</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <table class="tripple">
              <tr>
                <td>
                  Фактические проводки
                  <span>Связанные с плановой</span>
                </td>
                <td>
                  <v-chip class="ma-1" close color="#E3EBFC"> 1832845754 </v-chip>
                </td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
              <tr>
                <td>
                  Плановые проводки
                  <span>Связанные с фактической</span>
                </td>
                <td>
                  <v-chip class="ma-1" close color="#E3EBFC"> 1832845754 </v-chip>
                </td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
              <tr>
                <td>Родительская проводка</td>
                <td></td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
              <tr>
                <td>Дочерние проводки</td>
                <td></td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>
              Данные из 1С
              <v-btn outlined class="ml-2">Удалить данные</v-btn>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <table class="double">
              <tr>
                <th>Период</th>
                <td>19.11.2020</td>
              </tr>
              <tr>
                <th>Документ</th>
                <td>
                  Списание с расчетного счета НИС00000109 от 29.11.2020 0:00:00
                  Оплата за выполненные работы по договору 01/25/62-20-2 от
                  20.09.2020 «Подготовка предложений по созданию сервиса «бизнес
                  под ключ»...» по вх.д.86 от
                </td>
              </tr>
              <tr>
                <th>Аналитика Дт</th>
                <td>
                  КИБЕРГРУППА Договор 01/25/62-20-2 от 20.09.2020 Поступление
                  товаров и услуг НИС00000025 от 22.10.2020 11:47:30
                </td>
              </tr>
              <tr>
                <th>Аналитика Кт</th>
                <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
              </tr>
              <tr>
                <th>Дебет – счет</th>
                <td>60.01</td>
              </tr>
              <tr>
                <th>Дебет – сумма</th>
                <td>1 000 000,00 руб.</td>
              </tr>
              <tr>
                <th>Кредит – счет</th>
                <td>51</td>
              </tr>
              <tr>
                <th>Кредит – сумма</th>
                <td>1 000 000,00 руб.</td>
              </tr>
              <tr>
                <th>Дебет – счет</th>
                <td>60.01</td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Права доступа</h2>
            <span>Сотрудник или группа пользователей</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <table class="tripple">
              <tr>
                <td>
                  <span>Просмотр</span>
                </td>
                <td>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Сотрудники ГК НИСИПП
                  </v-chip>
                </td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Редактирование и удаление</span>
                </td>
                <td>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Краснов С.М.
                  </v-chip>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Шевернев Ю.А.
                  </v-chip>
                </td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Уведомление в ЛК</span>
                </td>
                <td>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Краснов С.М.
                  </v-chip>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Шевернев Ю.А.
                  </v-chip>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Старожилец Л.М.
                  </v-chip>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Шеховцов А.О.
                  </v-chip>
                  <v-chip class="ma-1" close color="#E3EBFC">
                    Литвак Е.Г.
                  </v-chip>
                </td>
                <td>
                  <a href="#">+ добавить</a>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-alert v-show="errorMessage" outlined dense type="error">{{
          errorMessage
        }}</v-alert>
        <v-row class="mt-16">
          <v-col cols="12">
            <v-btn outlined onclick="history.back()">Отменить</v-btn>

            <v-btn
              @click="
                // if (editFormValidator(wData)) {
                //   wData.postData();
                // } else {
                //   wData.onError();
                //   wData.toConsole();
                //   wData.postData();
                // }
                wData.postData();
              "
              >Создать проводку
            </v-btn>
            
          </v-col>
        </v-row>
      </section>
    </v-form>
  </v-container>
</template>

<script>
import { loadDataToObject } from '@/modules/CommonUtils';
import { projectKeyHolderNew } from '@/modules/KeysHolder.js';
import { wPost } from '@/modules/Wirings';
import { ourOrgsAccountsDict } from '@/modules/NSI';
import api from '@/modules/api';
import RegisterSelectField from '@/components/register/RegisterSelectField';
import {
  projectsRegisterConfig,
  customerContractsRegisterConfig,
  supplierContractsRegisterConfig,
  customerCounterpartiesRegisterConfig,
  supplierCounterpartiesRegisterConfig,
} from '@/components/register/RegisterConfigs';
import {
  articlesDict,
  fundsDict,
  editFormValidator,
} from '@/components/register/WireDict';
import { normalizeNumber } from '@/modules/CommonUtils';
import DateField from '@/components/elements/DateField';
import dayjs from 'dayjs';

export default {
  name: 'NewWiringComponent',
  props: {},
  components: { RegisterSelectField, DateField },
  data() {
    return {
      apiLoaded: false,
      isDisabled: false,
      dialog: false,
      // Mock Article
      articles: [],
      // Mock Fund
      funds: [],
      paymentMethods: [],
      resStatus: 0,
      years: [2020, 2021, 2022, 2023, 2024],
      year: new Date().getFullYear(),
      yearDisabled: false,
      wData: {
        accountNumber: '',
        article: '',
        budgetYear: new Date().getFullYear(),
        comment: '',
        contractId: '',
        contractor: '',
        expert: '',
        organization: null,
        paymentDate: '',
        paymentMethod: '',
        project: '',
        funds: '',
        sum: '',
        wiringType: 1,
        postData: async () => {
          const status = await this.wPost(this.year, JSON.stringify(this.wData));
          if (status == 200) {
            if (this.projectId) {
              history.back();
              // this.$router.push('/projects/' + this.projectId);
            } else {
              history.back();
              // this.$router.push('/entries/');
            }
          }
        },
        onError: () => {
          this.errorMessage = 'Не все обязательные поля заполнены';
        },
      },
      errorMessage: '',
      contracts: [],
      projectId: this.$route.params.id,
      config: projectsRegisterConfig,

      ourOrgs: [],
      experts: [],
      entries: [],
      entriesDict: [],
      encrypted: {},
      counterpartiesRegisterConfig: {
        tabs: [
          {
            title: 'Заказчики',
            config: customerCounterpartiesRegisterConfig,
          },
          {
            title: 'Подрядчики',
            config: supplierCounterpartiesRegisterConfig,
          },
        ],
      },
      customerContractsRegisterConfig: {
        tabs: [
          { title: 'С заказчиками', config: customerContractsRegisterConfig },
          { title: 'С поставщиками', config: supplierContractsRegisterConfig },
        ],
      },
      articlesDict: articlesDict,
      fundsDict: fundsDict,
      accountsDict: [],
      rules: {
        select: [(v) => !!v || 'Обязательное поле.'],
      },
    };
  },
  watch: {
    async year(newValue, oldValue) {
      console.log('year changed', this.year, newValue, oldValue);
      this.loadPage();
    },
  },
  methods: {
    loadData: loadDataToObject,
    normalizeNumber: normalizeNumber,
    wPost: wPost,
    checkValidate() {
      console.log(this.$refs.form)
      console.log(this.$refs.form.validate())
    },
    editFormValidator: editFormValidator,
    getTodayDateString: () => {
      return dayjs().format('YYYY-MM-DD');
    },
    async loadPage() {
      await projectKeyHolderNew.checkKey();
      let portion = (
        await api.get(
          this.config.apiRestEndpoint +
            (this.year ? this.year : new Date().getFullYear())
        )
      ).payload;
      console.log('portion: ', portion);

      for (let i = 0; i < portion.length; i++) {
        let decrypted = await projectKeyHolderNew.decode(
          portion[i].data
        );
        decrypted.id = portion[i].id;
        portion[i] = decrypted;
      }
      this.entries = portion.filter((e) => {
        return e ? true : false;
      });
      this.entries.sort((a, b) => {
        switch (this.sortField) {
          case 'id':
            return (this.sortAsc ? 1 : -1) * (a.id - b.id);
          case 'number':
            return (this.sortAsc ? 1 : -1) * (a.number > b.number ? 1 : -1);
          case 'status':
            return (this.sortAsc ? 1 : -1) * (a.status > b.status ? 1 : -1);
          case 'offerExpert.fullName':
            return (
              (this.sortAsc ? 1 : -1) *
              (a.offerExpert?.fullName > b?.offerExpert.fullName ? 1 : -1)
            );
        }
      });
      this.entriesDict = this.entries;
      console.log(this.entries);
    },
  },
  async beforeMount() {
    console.log('projectId', this.projectId);
    await Promise.all([
      this.loadData('/supmain/contracts', 'contracts', true),
      this.loadData(
        '/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:nullfalse',
        'counterparties',
        true
      ),
      this.loadData(
        '/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:true',
        'ourOrgs',
        true
      ),
      this.loadData(
        // '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        '/supmain/experts?page=0&size=100&sort=id,asc',
        'experts',
        true
      ),
      // this.loadData('/supmain/projects/' + this.projectId, 'encrypted', false),
    ]);
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        return e;
      });
    };
    if (this.projectId) {
      await this.loadData(
        '/supmain/projects/' + this.projectId,
        'encrypted',
        false
      );
      await projectKeyHolderNew.checkKey();
      this.projectCommonInfo = await projectKeyHolderNew.decode(
        this.encrypted.data
      );
      console.log('encrypted', this.encrypted);
      console.log('projectCommonInfo', this.projectCommonInfo);
      this.wData.project = await this.projectCommonInfo;

      console.log('this.wData.project', this.wData.project);
    }
    if (this.wData.project && this.wData.project.year) {
      this.year = this.wData.project.year;
      this.wData.budgetYear = this.wData.project.year;
      this.yearDisabled = true;
    }
    await this.loadPage();
    this.experts = getFullName(this.experts);
    this.ourOrgs = ourOrgsAccountsDict;
    this.wData.project = this.entries.filter(
      (el) => el.id == this.projectId
    )[0];
    if (this.wData.project?.name) {
      this.isDisabled = true;
    }
    // this.wData.organization = this.entries[0];

    console.log('projectCommonInfo', this.projectCommonInfo);
    console.log('wData b4', this.wData);

    this.apiLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.add-link {
  display: inline-block;
  margin-bottom: 30px;
}

.form-wrap h4 {
  text-transform: uppercase;
}

h2 + span {
  display: block;
  font-size: 12px;
  color: #77809a;
  margin-top: -16px;
}

.form-wrap {
  padding-right: 0;
  table {
    width: 100%;
    th {
      border-top: none;
    }
    td:first-child {
      padding-left: 0;
    }
    td:last-child {
      padding-right: 0;
    }
    th:first-child {
      padding-left: 0;
    }
    th:last-child {
      padding-right: 0;
    }
    &.double {
      td {
        width: 50%;
      }
    }
    &.tripple {
      td:first-child {
        font-size: 13px;
        padding-left: 0;
        span {
          display: block;
          color: #77809a;
        }
      }
      td:nth-child(2) {
        text-align: right;
      }
      td:last-child {
        width: 75px;
        text-align: right;
        white-space: nowrap;
        padding-right: 0;
      }
    }
  }
}
</style>
